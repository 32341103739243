@import "mixins";

.navbar{
    .navbar-toggler{
        color:white;
        border-color: white;
        .icon-bar {
            background-color: white !important;
        }
    }

    .navbar-nav{
        
        &.menu-left{
            //Item selecionado
            &>li.active{
                &>a.nav-link, &>a.nav-link:focus, &>a.nav-link:hover{
                    color: $color-yellow;
                }
            }

            //Item padrão
            &>li{
                &>a.nav-link{
                    color: white;
                    font-size: 1rem;
                    margin: 5px;
                    font-weight: 400;
                    @include transition(all, 200ms)
                }
            }

            //Item hover
            &>li{
                &>a.nav-link:focus, &>a.nav-link:hover{
                    color: $color-yellow;
                }
            }

            .dropdown-menu{
                &>.active{
                    &>a.nav-link{
                        
                    }
                }
            }
        }
    }

    .navbar-brand{
        img{
            width: 180px;
        }
    }
}

.wrap-icon-media{
    a{
        margin-left: 0.5rem;
        color: $color-yellow;

        &:hover{
            color: white;
        }
    }
}