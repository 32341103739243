@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import "fonts";
@import "variable";
@import "navbar";
@import "mixins";
@import "helper";
@import "home";
@import "posts";
@import "contact";

body, html{
    @extend %font-regular;
    font-size: 16px;
}
a:hover, a:active{
    text-decoration: none;
    color: inherit;
}

a.hover-yellow:hover{
    color: $color-yellow;
}

p{
    color: #555;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

h2{
    &.yellow{
        color: $color-yellow;
    }
}

header{
    background-color: $color-purple;
    border-bottom: 15px solid $color-yellow;
}

footer{
    background-color: $color-purple;
    border-bottom: 15px solid $color-yellow;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 0.8rem;
    a{
        margin-right: 1rem;
        &:hover{
            color: $color-yellow;
        }
    }
}

.wrap-press{
    background-color: #f1f1f1;
    h5{
        color: $color-purple;
    }
    a{
        color: $color-yellow;
    }
}

@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .navbar .navbar-brand img {
        width: 200px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .bg-top-main{
        h2{
            font-size: 1.8rem;
            padding-right: 2rem;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

