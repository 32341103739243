.color-purple{
    color: $color-purple;
}
.color-white{
    color: white;
}
.color-yellow{
    color: $color-yellow;
}
.bg-purple{
    background-color: $color-purple;
}
.bg-light-grey{
    background-color: $color-light-grey;
}