.mail_detail{
    margin-bottom: -40px;
    z-index: -1;
    position: relative;
}

.wrap-content{
    .title{
        color: $color-purple;
        font-size: 1.2rem;
    }

    .btn-pills{
        border: 1px solid $color-yellow;
        background-color: $color-yellow;
        padding: 5px 25px;
        border-radius: 50px;
        color: $color-purple;
        display: inline-block;
    
        &:hover{
            background-color: transparent;
            color: $color-yellow;
        }
    }
}