.wrap-posts, .wrap-post{
    border-bottom: 1px solid #9a999e;
    h2{
        a{
            font-size: 1.4rem;
            color: $color-purple;
        }
    }

    span.category{
        display: block;
        color: $color-yellow;
        font-size: 1rem;
    }

    p{
        color: $color-grey;
    }

    .date{
        font-size: 0.8rem;
        i{
            color: $color-purple;
        }
    }
}

.btn-pills{
    border: 1px solid $color-yellow;
    background-color: $color-yellow;
    padding: 5px 25px;
    border-radius: 50px;
    color: white;
    display: inline-block;

    &:hover{
        background-color: transparent;
        color: $color-yellow;
    }
}

.wrap-pagination{
    a{
        @extend .btn-pills;
        padding: 3px 15px;
        border: 1px solid $color-purple;
        background-color: $color-purple;
        &:hover{
            color: $color-purple;
        }
    }
}

.wrap-post{
    border: 0;
    font-family: 'Open Sans',sans-serif;
    font-weight: 300;
    b,strong{
        font-weight: 700;
    }
    h2{
        a{
            font-size: 1.8rem;
        }
    }

    figcaption{
        font-style: italic;
        font-weight: 300;
        font-family: 'Open Sans',sans-serif;
        font-size: 0.8rem;
    }
    figure{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}