@font-face {
    font-family: 'Coolvetica Crammed Rg';
    src: url('../fonts/CoolveticaCrammedRg-Regular.eot');
    src: url('../fonts/CoolveticaCrammedRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CoolveticaCrammedRg-Regular.woff2') format('woff2'),
        url('../fonts/CoolveticaCrammedRg-Regular.woff') format('woff'),
        url('../fonts/CoolveticaCrammedRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Rg';
    src: url('../fonts/CoolveticaRg-Regular.eot');
    src: url('../fonts/CoolveticaRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CoolveticaRg-Regular.woff2') format('woff2'),
        url('../fonts/CoolveticaRg-Regular.woff') format('woff'),
        url('../fonts/CoolveticaRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Condensed Rg';
    src: url('../fonts/CoolveticaCondensedRg-Regular.eot');
    src: url('../fonts/CoolveticaCondensedRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CoolveticaCondensedRg-Regular.woff2') format('woff2'),
        url('../fonts/CoolveticaCondensedRg-Regular.woff') format('woff'),
        url('../fonts/CoolveticaCondensedRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica Compressed Rg';
    src: url('../fonts/CoolveticaCompressedRg-Regular.eot');
    src: url('../fonts/CoolveticaCompressedRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CoolveticaCompressedRg-Regular.woff2') format('woff2'),
        url('../fonts/CoolveticaCompressedRg-Regular.woff') format('woff'),
        url('../fonts/CoolveticaCompressedRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

%font-crammed{
    font-family: 'Coolvetica Crammed Rg';
    font-weight: normal;
    font-style: normal;
}

%font-regular{
    font-family: 'Coolvetica Rg';
    font-weight: normal;
    font-style: normal;
}
%font-condensed{
    font-family: 'Coolvetica Condensed Rg';
    font-weight: normal;
    font-style: normal;
}
%font-compressed{
    font-family: 'Coolvetica Compressed Rg';
    font-weight: normal;
    font-style: normal;
}