.bg-top-main{
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 350px;
    background-image: url('../images/bg_main.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    h2{
        color: white;
    }
}

.about-us{
    p{
        font-weight: 600;
    }
}

.wrap-default-shot{
    .block-figure{
        img{
            height: 220px;
            margin-bottom: 1rem;
        }
        line-height: 1.1rem;
        color: white;
        font-weight: 300;
    }
}

.wrap-home-posts{
    background-color: #f1f1f1;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .home-post{
        .image{
            a{
                height: 215px;
                display: flex;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .title{
            display: flex;
            align-items: center;
            padding: 15px;
            background-color: $color-purple;
            a{
                display: flex;
                align-items: center;
                color: white;

                &:hover{
                    color: $color-yellow;
                }
                i{
                    color: $color-yellow;
                    margin-left: 15px;
                }
            }
        }
    }
}

.wrap-twitter-category{
    #twitter-feed-dosescertas{
        background-color: $color-purple;
        color: white !important;
        padding: 10px 15px;
        a{
            color: $color-yellow !important;
        }
    }
    .fts-tweeter-wrap .tweeter-info{
        border-color: $color-yellow !important;
    }
    .tweeter-info .fts-twitter-text{
        color: white !important;
    }
}

.anchor a{
    color: white !important;
}